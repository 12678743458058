<template>
  <iframe id="mapPage" width="100%" height="100%" frameborder=0
          src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=GLIBZ-DIUE5-R64IC-QMFQM-6OLRK-QAFLG&referer=myapp">
<!--    <div id="txMap"></div>-->
  </iframe>
</template>

<script>

export default {
  data () {
    return {
      currentLocal: ""
    }
  },
  methods: {
    initMap () {

      var geolocation = new window.qq.maps.Geolocation("GLIBZ-DIUE5-R64IC-QMFQM-6OLRK-QAFLG", "myapp");
      geolocation.getLocation((position) => {
        let center = new TMap.LatLng(position.lat, position.lng)
        //定义map变量，调用 TMap.Map() 构造函数创建地图
        let map = new window.TMap.Map(document.getElementById('txMap'), {
          center: center,//设置地图中心点坐标
          zoom: 17.2,   //设置地图缩放级别
          pitch: 43.5,  //设置俯仰角
          rotation: 45    //设置地图旋转角度
        });
      }, (error) => {

      }, {timeout: 8000});

      window.addEventListener('message', (event) => {
        // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
        var loc = event.data;
        if (loc && loc.module == 'locationPicker') {//防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
          const {latlng,poiaddress,poiname} = loc;
          const addressInfo = {latlng,poiaddress,poiname}
         setTimeout(() => {
           this.$router.push({
             path:"/addCourse",
             query:{
               addressInfo
             }
           })
         },500)
        }
      }, false);
    }
  },
  mounted () {
    this.initMap()
  }
}
</script>
<style>

</style>
